.DragPreview .source-preview {
  border-radius: 5px;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  opacity: 0.5;
  z-index: 9999;
  left: 0;
  top: 0;
  transition: none;
  pointer-events: none;
  -webkit-touch-callout: none;
}
