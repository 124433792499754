.series-quick-switch {
  position: relative;
}
.series-quick-switch:before {
  box-shadow: 0 0 0 5000px rgba(0,0,0,0.8);
  transition: opacity 0.3s ease;
  opacity: 0;
  content: '';
}
.series-quick-switch:hover {
  z-index: 50;
}
.series-quick-switch:hover:before {
  display: table;
  opacity: 1;
  content: '';
}
.series-quick-switch:not(.left):not(.right) {
  left: 50%;
  transform: translateX(-50%);
}
.series-quick-switch.left {
  border-right: 1px solid var(--ui-border-color);
  right: 0;
}
.series-quick-switch.right {
  left: 0;
}
.series-quick-switch.right .series-switch {
  float: right;
}
.series-quick-switch.right .study-switch {
  float: left;
}
.series-quick-switch.right .study-browser {
  left: auto;
  right: 0;
  transform-origin: calc(100% - 1em) calc(1em + 17px);
}
.series-quick-switch.right .series-browser {
  left: 0;
  right: auto;
  transform-origin: 1em calc(1em + 15px);
}
.series-quick-switch.right .series-browser .thumbnails-wrapper {
  transform-origin: 0% 0%;
}
.series-quick-switch.right .series-browser .ThumbnailEntry {
  float: left;
}
.series-quick-switch.right .series-item {
  float: left;
}
.series-quick-switch .title-label {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  opacity: 1;
  padding-bottom: 3px;
  text-align: center;
  transition: opacity 0.3s ease;
}
.series-quick-switch .series-switch {
  padding-right: 10px;
}
.series-quick-switch .series-switch,
.series-quick-switch .study-switch {
  float: left;
  position: relative;
}
.series-quick-switch .study-switch:hover:after {
  content: '';
  display: block;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 300px;
}
.series-quick-switch .study-browser {
  left: 0;
  padding-top: 60px;
  max-height: calc(100vh - 60px);
  transform-origin: 1em calc(1em + 17px);
  width: 300px;
}
.series-quick-switch .study-browser .study-browser-list {
  padding: 0 10px;
}
.series-quick-switch .series-browser {
  max-height: 100vh;
  max-width: 720px;
  right: 0;
  transform-origin: calc(100% - 1em) calc(1em + 15px);
}
.series-quick-switch .series-browser .thumbnails-wrapper {
  transition: transform 0.3s ease;
  transform-origin: 100% 0%;
}
.series-quick-switch .series-browser .ThumbnailEntry {
  float: right;
}
.series-quick-switch .series-browser .ThumbnailEntry .series-details {
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.series-quick-switch .series-browser .scrollable {
  padding-top: 15px;
  transition: padding-bottom 0.3s ease;
}
.series-quick-switch .series-browser .study-browser-series {
  overflow: hidden;
}
.series-quick-switch .series-browser,
.series-quick-switch .study-browser {
  min-height: 120px;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 3;
}
.series-quick-switch .series-box,
.series-quick-switch .study-box {
  height: 57px;
  width: 57px;
}
.series-quick-switch .study-box {
  background-color: var(--ui-gray-dark);
  border: solid 2px var(--ui-border-color-dark);
  border-radius: 11px;
}
.series-quick-switch .series-item {
  background-color: var(--box-background-color);
  border-radius: 3px;
  float: right;
  height: 15px;
  margin: 2px;
  transition: opacity 0.3s ease;
  width: 15px;
}
.series-quick-switch .series-item.count {
  color: var(--text-primary-color);
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}
.series-quick-switch .series-item.active {
  background-color: var(--active-color);
}
.series-quick-switch.series-triggered .series-browser,
.series-quick-switch .series-switch:hover .series-browser,
.series-quick-switch .study-switch:hover .study-browser {
  opacity: 1;
  transform: scale(1);
}
.series-quick-switch.series-triggered .series-item,
.series-quick-switch .series-switch:hover .title-label {
  opacity: 0;
}
.series-quick-switch.series-triggered .thumbnails-wrapper {
  transform: scale(0.9) translateY(80px);
}
.series-quick-switch.series-triggered .series-browser:not(:hover)>.scrollable {
  padding-bottom: 80px;
}
.series-quick-switch.series-triggered .series-browser:not(:hover)>.scrollNav {
  opacity: 0;
}
.series-quick-switch .series-switch:hover .series-browser .thumbnails-wrapper {
  transform: scale(1) translateY(0);
}
.series-quick-switch .series-switch:hover .series-browser .ThumbnailEntry .series-details {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}
.series-quick-switch .study-browser-item.active .study-item-box {
  box-shadow: inset 0 0 0 3px var(--active-color);
}
@media screen and (max-width: 1599px) {
  .series-quick-switch .series-box,
  .series-quick-switch .study-box {
    width: 36px;
    height: 36px;
  }
  .series-quick-switch .study-box {
    border-radius: 7px;
  }
  .series-quick-switch .series-item {
    height: 10px;
    margin: 1px;
    width: 10px;
  }
  .series-quick-switch .series-item.count {
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
  }
}
