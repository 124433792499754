* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.selectTreeRoot {
  text-align: initial;
  width: 320px;
  max-height: inherit;
  font-size: 14px;
  line-height: 26px;
}
.selectTreeRoot .treeContent {
  max-height: inherit;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.selectTreeRoot .treeHeader {
  background-color: #c0c0c0;
  border-bottom: 1px solid #c4c4c4;
  color: #303030;
  padding: 5px;
  text-align: center;
}
.selectTreeRoot .treeHeader .wrapperText {
  font-weight: bold;
  padding-bottom: 2px;
}
.selectTreeRoot .treeHeader .wrapperSearch {
  width: 100%;
}
.selectTreeRoot .treeHeader .searchIcon {
  position: absolute;
  width: 18px;
  height: 15px;
  margin: 7px 10px 13px 10px;
  color: #a3a3a3;
}
.selectTreeRoot .treeHeader .searchInput {
  width: 100%;
  padding: 6px 12px 6px 35px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.selectTreeRoot .treeHeader .searchInput:focus {
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.selectTreeRoot .wrapperText {
  white-space: nowrap;
  cursor: pointer;
}
.selectTreeRoot .selectTreeBreadcrumb .wrapperLabel {
  cursor: pointer;
  padding: 0 12px;
  height: 41px;
  width: 100%;
  line-height: 41px;
}
.selectTreeRoot .selectTreeBreadcrumb .backIcon {
  padding-right: 10px;
}
.selectTreeRoot .selectTreeBreadcrumb .backIcon svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  color: #457fb0;
}
.selectTreeRoot .treeOptions {
  overflow: auto;
  height: 100%;
}
.selectTreeRoot .treeOptions .selectTreeBreadcrumb + .treeInputsWrapper .treeInputs .treeLeaf {
  border: none;
  height: initial;
  line-height: 26px;
}
.selectTreeRoot .treeOptions > .treeInputsWrapper > .treeInputs .treeLeaf {
  border-bottom: 1px solid #ccc;
  height: 41px;
  line-height: 41px;
}
.selectTreeRoot .treeNode,
.selectTreeRoot .treeLeaf {
  cursor: pointer;
  display: block;
  overflow: hidden;
  font-weight: normal;
  box-shadow: 0 0 0 200px transparent;
  padding: 0 12px;
}
.selectTreeRoot .treeNode {
  border-bottom: 1px solid #ccc;
  height: 41px;
  line-height: 41px;
}
.selectTreeRoot .radioLabel .radioInput {
  display: none;
}
