.studyListToolbar .addNewStudy {
  margin: 0 10px;
  color: var(--text-secondary-color);
}
.studyListToolbar .addNewStudy label {
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
.studyListToolbar .addNewStudy input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.studyListToolbar .addNewStudy:hover {
  color: var(--hover-color);
}
.studyListToolbar .addNewStudy:active {
  color: var(--active-color);
}
