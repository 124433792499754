.StudyBrowser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding-bottom: 20px;
  padding-top: 10px;
}
.StudyBrowser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
}
.StudyBrowser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.StudyBrowser .scrollable-study-thumbnails .ThumbnailEntryContainer {
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
.StudyBrowser .scrollable-study-thumbnails .ThumbnailEntryContainer .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.thumbnail-anchor {
  color: #91b9cd;
  cursor: pointer;
}
.thumbnail-anchor:hover {
  color: #fff;
}
